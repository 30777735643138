import React, { createRef } from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import resources from '../../utils/resources';

import Screen from '../screen';

import WordingData from '../../utils/wording.json';
import { log, TagLogger } from '../../utils/logger';
import { logScreen } from '../../utils/firebase';
import { sumPackageAmountInstallmentFrequency } from '../../utils/helpers';

import { gtagReportConversion, facebookPixel } from '../../utils/tags';

import { TimeOneConversionTag } from '../../components';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import { scrollToRefDelay } from '../../utils/scroll';
import { prepareContract } from '../FinalSummaryScreen/Contract.actions';
import Config from '../../utils/config';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingData.FinalScreen;
const WordingLinks = WordingConstant.ValidateTimer;

export class FinalScreen extends React.Component {
    static navigationOptions = {
        nextScreen: null,
        previousScreen: null,
        buttonNextTitle: '',
        buttonPreviousTitle: '',
        title: Wording.title,
        screenId: Screen.FINAL,
        showSaveButton: false,
        step: 4,
        validate: () => {},
    };

    constructor(props) {
        super(props);
        this.myRef = createRef();
        this.state = {
            isLoading: true,
            isOpen: this.isSelectra(),
            loadingContract: false,
        };
    }

    componentDidMount() {
        gtagReportConversion();
        facebookPixel();

        logScreen(TagLogger.CONVERSION);

        log({
            tag: TagLogger.CONVERSION,
            returnCode: '200',
            logMessage: null,
            serviceCalled: null,
            statusAction: 'SUCCESS',
            triggerButton: 'auto-loading',
        });

        // if (this.props.orderReference !== null) {
        //     this.setState({ isLoading: false });
        //     scrollToRefDelay(this.myRef);
        // }
        setTimeout(() => {
            this.setState({ isLoading: false });
            scrollToRefDelay(this.myRef, 200);
        }, 10000);
    }

    getPrice() {
        const {
            userState: { energyTypes },
            summaryState,
        } = this.props;

        const reducer = (accumulator, currentValue) => {
            const { chosenPackages, installmentFrequency } = summaryState[
                currentValue
            ];

            return (
                accumulator +
                sumPackageAmountInstallmentFrequency(
                    chosenPackages,
                    installmentFrequency
                )
            );
        };
        return energyTypes.reduce(reducer, 0);
    }

    isSelectra = () => {
        const channel = localStorage.getItem('channel');

        return channel;
    };

    handleContract = async () => {
        this.setState({ loadingContract: true });
        try {
            if (localStorage.getItem('reduxState')) {
                const payloadContract = prepareContract(
                    JSON.parse(localStorage.getItem('reduxState'))
                );

                if (
                    Array.isArray(payloadContract) &&
                    payloadContract.length > 0
                ) {
                    const promises = payloadContract.map(
                        async (contract, index) => {
                            try {
                                const headers = {
                                    'x-api-key': Config.ApiKey,
                                };

                                const res = await fetch(
                                    `${Config.URL}/getContract`,
                                    {
                                        method: 'POST',
                                        body: JSON.stringify(contract),
                                        headers,
                                    }
                                );

                                if (res.ok) {
                                    const blob = await res.blob();
                                    const fileURL = URL.createObjectURL(blob);

                                    window.open(fileURL, `_blank_${index}`);
                                } else {
                                    console.error(
                                        `La requête a échoué avec le statut ${res.status}`
                                    );
                                }
                            } catch (error) {
                                console.error(
                                    `Erreur lors de la récupération du contrat (index ${index}):`,
                                    error
                                );
                            }
                        }
                    );
                    await Promise.all(promises);
                    this.setState({ loadingContract: false });
                }
            }
        } catch (error) {
            this.setState({ loadingContract: false });
            console.error(
                'Erreur générale lors de la récupération des contrats :',
                error
            );
        }
    };

    handleFilePdf = async () => {
        try {
            const pdfUrl = "/static/Document d'informations.pdf";
            const encodedUrl = encodeURI(pdfUrl);
            const correctedUrl = encodedUrl.replace(/(%2F|%20)/g, match =>
                match === '%2F' ? '/' : ' '
            );

            window.open(correctedUrl, '_blank', 'noopener noreferrer');
        } catch (e) {
            console.error('Impossible de récupérer le fichier pdf', e);
        }
    };

    render() {
        const {
            orderReference,
            userState: { energyTypes },
            summaryState,
        } = this.props;

        const payloadContracts = summaryState.contracts;
        const { isLoading, loadingContract } = this.state;

        return (
            <>
                <div ref={this.myRef} />
                <SectionLayout>
                    <div className="final-screen">
                        <TimeOneConversionTag
                            transactionId={orderReference}
                            price={this.getPrice()}
                            additionalData={energyTypes.join('')}
                        />

                        <>
                            <img
                                className="final-screen__img"
                                src={resources['ic-succes']}
                                alt={Wording.text1}
                            />
                            <h1 className="final-screen__text">
                                {Wording.text1}
                            </h1>
                            <div className="final-screen__sou">
                                {Wording.text2}
                            </div>
                            <div className="final-screen__displayOrderReference">
                                Note dès maintenant ton numéro client :
                            </div>
                            {isLoading && (
                                <div className="final-screen__displayOrderReference">
                                    Loading...
                                </div>
                            )}
                            {!isLoading && orderReference !== null && (
                                <div className="final-screen__displayOrderReference">
                                    <strong>{orderReference}</strong>
                                </div>
                            )}
                            {[
                                'C2E',
                                'DEPANN',
                                'GROUPE-POWER',
                                'POWER-DISTRIB',
                            ].includes(this.state.isOpen) ? (
                                <div>
                                    <p className="validate-timer-link">
                                        {WordingLinks.popup.bottomLinks
                                            .filter(
                                                link =>
                                                    link.text &&
                                                    (link.link || link.api)
                                            )
                                            .map((link, i) => {
                                                if (
                                                    link.value ===
                                                    'INFORMATION_DOCUMENT'
                                                ) {
                                                    return (
                                                        <span
                                                            className="validate-timer-link"
                                                            key={`linkFooterBottom${i}`}
                                                            onClick={
                                                                this
                                                                    .handleFilePdf
                                                            }
                                                            rel="noopener noreferrer"
                                                        >
                                                            {link.text}
                                                        </span>
                                                    );
                                                }
                                                if (link.link) {
                                                    return (
                                                        <a
                                                            className="validate-timer-link"
                                                            key={`linkFooterBottom${i}`}
                                                            href={link.link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {link.text}
                                                        </a>
                                                    );
                                                }
                                                if (link.api) {
                                                    const isSingleContract =
                                                        Array.isArray(
                                                            payloadContracts
                                                        ) &&
                                                        payloadContracts.length ===
                                                            1;
                                                    const linkText = isSingleContract
                                                        ? 'Ton contrat'
                                                        : 'Tes contrats';
                                                    if (loadingContract) {
                                                        return (
                                                            <>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faCircleNotch
                                                                    }
                                                                    spin
                                                                    size="lg"
                                                                    style={{
                                                                        color:
                                                                            'rgb(81 81 81)',
                                                                    }}
                                                                />
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            '#6f49ce',
                                                                    }}
                                                                >
                                                                    &nbsp;|
                                                                </span>
                                                            </>
                                                        );
                                                    }
                                                    return (
                                                        <div
                                                            className="validate-timer-link__contract"
                                                            key={`linkFooterBottom${i}`}
                                                            onClick={
                                                                this
                                                                    .handleContract
                                                            }
                                                        >
                                                            {linkText} |
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <React.Fragment
                                                        key={`linkFooterBottom${i}`}
                                                    />
                                                );
                                            })}
                                    </p>
                                </div>
                            ) : (
                                <div className="final-screen__mail">
                                    {Wording.text3}
                                </div>
                            )}
                        </>
                    </div>
                    <div className="final-screen__footer" />
                </SectionLayout>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    orderReference: state.paymentReducer.reference,
    summaryState: state.summaryReducer,
});

export default connect(mapStateToProps, null)(FinalScreen);
