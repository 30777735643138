import React, { createRef } from 'react';
import { connect } from 'react-redux';

import SectionLayout from '../../components/SectionLayout/SectionLayout';
import Screen from '../../screens/screen';
import Title from '../../components/Typography/Title';
import Typography from '../../components/Typography/Typography';
import { scrollToRefDelay } from '../../utils/scroll';
import LabeledInput from '../../components/LabeledInput/LabeledInput';
import { CustomButton, CustomCheckbox } from '../../components';
import { CheckBoxVariant } from '../../components/CustomCheckbox/CustomCheckbox';
import {
    BillingMode,
    CodeScreenMode,
    FontSizeVariant,
} from '../../constants/enums';
import './_CodeContainer.scss';
import WordingConstant from '../../utils/wording.json';
import { handleConditionPDLPCE } from '../../utils/helpers';
import {
    changeCodeMode,
    validateCodeScreen,
    resetFullCodeScreen,
} from '../../screens/CodeScreen/CodeScreen.actions';
import DividerDashOr from '../../components/DividerDashOr/DividerDashOr';
import OfferSelector from './OfferSelector';
import { validatePackageScreen } from '../../screens/PackageScreen/PackageScreen.actions';
import { TagLogger, TriggerButtonLogger } from '../../utils/logger';
import SurveyScreen from '../../screens/SurveyScreen';

const Wording = WordingConstant.CodeScreen;

const energyWording = (path, energyTypes) => {
    const isBoth = energyTypes.length === 2;
    return isBoth ? Wording[path].ELNG : Wording[path][energyTypes[0]];
};

class CodeContainerV2 extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.CODE,
        previousScreen: Screen.SUMMARY,
        buttonNextTitle: '',
        buttonPreviousTitle: '',
        title: '',
        screenId: Screen.DATA,
        step: 3,
        validate: () => {},
        disabled: () => {},
        returnButton: () => {},
        saveData: () => {},
        checkFirstModuleCondition: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {
            EL: '',
            NG: '',
            rgpd: false,
            errorFields: {
                EL: undefined,
                NG: undefined,
            },
            showError: true,
            offerSelection: BillingMode.PAYMENT_SCHEDULE,
        };
        this.myRef = createRef();
        this.myRefOffers = createRef();
        this.checkFirstModuleCondition = this.checkFirstModuleCondition.bind(
            this
        );
        this.validate = this.validate.bind(this);
    }

    componentDidUpdate() {
        const {
            codeState: { currentMode },
            nextScreen,
            isCurrentScreen,
            changeCodeModeDispatch,
            summaryState: { contracts },
            dataState: {
                common: { firstAddress },
                logingInformation: { accessToken },
            },
            userState: { energyTypes },
        } = this.props;
        const state = this.state;
        const fakeEvent = new Event('click');
        const dynamicNextScreen =
            state.offerSelection === BillingMode.PAYMENT_SCHEDULE ? 3 : 4;
    
        if (currentMode === CodeScreenMode.END && isCurrentScreen)
            nextScreen(fakeEvent, dynamicNextScreen);
        contracts.forEach(contract => {
            if (
                contract.deliveryPoint.address &&
                contract.deliveryPoint.address.postalCode !== firstAddress.postalCode &&
                currentMode !== CodeScreenMode.NONE &&
                !accessToken
            ) {
                energyTypes.forEach(it => {
                    if (state[it] !== '') {
                        changeCodeModeDispatch({ mode: CodeScreenMode.NONE });
                    }
                });
            }
        });
    }

    componentDidMount() {
        CodeContainerV2.navigationOptions.validate = this.validate;
        const {
            isCurrentScreen,
            setCanGoNext,
            changeCodeModeDispatch,
            codeState,
        } = this.props;
        const { currentMode } = codeState;
        this.setState({
            EL: codeState.PDL,
            NG: codeState.PCE,
        });
        setCanGoNext(false);
        if (currentMode === CodeScreenMode.END)
            changeCodeModeDispatch({ mode: CodeScreenMode.NONE });
        if (
            isCurrentScreen &&
            (currentMode === CodeScreenMode.NONE ||
                currentMode === CodeScreenMode.END)
        )
            scrollToRefDelay(this.myRef, 200);
    }

    saveData = offerId => {
        const {
            userState: { userType, energyTypes },
            summaryState: { contracts },
            validatePackageScreenDispatch,
            setCanGoNext,
            changeCodeModeDispatch,
        } = this.props;
        let payload = {
            userType,
            energyTypes,
            contracts,
            logger: {
                tag: TagLogger.PACKAGE,
                triggerButton: TriggerButtonLogger.VALIDATE_BTN,
            },
        };
        const eneryField = {
            cyclicBill: offerId === BillingMode.CYCLICAL_BILLING,
            packageID: '',
            chosenPackages: [],
            installmentFrequency: '',
        };

        this.setState({
            offerSelection: offerId,
        });

        payload = Object.assign(
            {},
            payload,
            ...energyTypes.map(it => ({ [it]: eneryField }))
        );
        setCanGoNext(true);
        validatePackageScreenDispatch(payload);
        changeCodeModeDispatch({ mode: CodeScreenMode.END });
    };

    validate = (postalCodeCustomer) => {
        const {
            codeState: { currentMode },
            validateCodeScreenDispatch,
            userState: { userType, energyTypes },
            dataState: { common: {email, name, surname}, logingInformation: validated },
            isCurrentScreen,
            resetScreenTo,
        } = this.props;
        if (!isCurrentScreen) resetScreenTo();
        this.setState({ showError: true });
        const { EL, NG } = this.state;
        if (currentMode === CodeScreenMode.NONE)
            validateCodeScreenDispatch({
                PDL: EL,
                PCE: NG,
                energyTypes,
                userType,
                postalCodeCustomer,
                firstName: name,
                lastName: surname,
                email,
                isAlreadyClient: validated,
            });
        if (currentMode === CodeScreenMode.SIMULATION)
            SurveyScreen.navigationOptions.validate();
        if (currentMode === CodeScreenMode.END) return true;

        return false;
    };

    checkFirstModuleCondition() {
        const { rgpd, EL, NG } = this.state;
        const {
            userState: { energyTypes },
        } = this.props;
        const isBoth = energyTypes.length === 2;
        if (isBoth)
            return (
                rgpd && handleConditionPDLPCE(EL) && handleConditionPDLPCE(NG)
            );
        return (
            rgpd &&
            ((energyTypes[0] === 'EL' && handleConditionPDLPCE(EL)) ||
                (energyTypes[0] === 'NG' && handleConditionPDLPCE(NG)))
        );
    }

    handleSimilationValidate = state => {
        const { setCanGoNext } = this.props;
        setCanGoNext(state);
    };

    onClickButtonSimulation = () => {
        const {
            changeCodeModeDispatch,
            resetFullCodeScreenDispatch,
            isCurrentScreen,
            resetScreenTo,
        } = this.props;
        if (!isCurrentScreen) resetScreenTo();
        resetFullCodeScreenDispatch();
        changeCodeModeDispatch({ mode: CodeScreenMode.SIMULATION });
    };

    handleChange = (field, value) => {
        const {
            resetScreenTo,
            isCurrentScreen,
            codeState: { currentMode },
            changeCodeModeDispatch,
        } = this.props;
        const optionalSetter = {};
        let cleanValue = value;
        if (field === 'EL' || field === 'NG') {
            if (cleanValue !== undefined) {
                const regex = /\D|\d{15,}/g;
                const tmp = regex.test(cleanValue);
                if (tmp) cleanValue = cleanValue.slice(0, -1);
            }
            optionalSetter.errorFields = {
                [`${field}Error`]: !handleConditionPDLPCE(cleanValue),
            };
        }
        if (currentMode !== CodeScreenMode.NONE) {
            changeCodeModeDispatch({ mode: CodeScreenMode.NONE });
        }

        this.setState({
            [field]: cleanValue,
            ...optionalSetter,
        });

        if (!isCurrentScreen) resetScreenTo();
    };

    render() {
        const {
            userState: { energyTypes },
            codeState,
            isCurrentScreen,
            canGoNext,
            nextScreen,
            summaryState: { contracts },
            dataState: {
                common: { firstAddress },
                logingInformation: { accessToken },
            },
            mainState,
            isModalOpen,
        } = this.props;
        const { rgpd, errorFields, showError } = this.state;
        const { currentMode } = codeState;
        const state = this.state;
        let bakcendError = null;
        if (mainState && mainState.errorFrom === 'CODE_SCREEN_VALIDATE_FAILURE')
            bakcendError = mainState.error;
        if (currentMode === CodeScreenMode.OFFERS) {
            scrollToRefDelay(this.myRefOffers, 100);
        }
        contracts.forEach(contract => {
            if (
                contract.deliveryPoint.address &&
                contract.deliveryPoint.address.postalCode !==
                    firstAddress.postalCode &&
                !accessToken
            ) {
                errorFields[contract.energy] =
                    Wording.inputError.errorNotSamePostalCode;
            } else {
                errorFields[contract.energy] = undefined;
            }
        });

        energyTypes.forEach(it => {
            if (bakcendError) {
                if (currentMode === CodeScreenMode.NONE) {
                    errorFields[it] = bakcendError;
                } else {
                    errorFields[it] = undefined;
                }
            }
        });

        const titleFormatted = energyWording('codeTitle', energyTypes);
        const noCodeFormatted = energyWording('noCodeTitle', energyTypes);
        const noCodeSubFormatted = energyWording('noCodeSubTitle', energyTypes);
        const subTitleFormatted = energyWording('codeSubTitle', energyTypes);

        return (
            <>
                <div ref={this.myRef} />
                <SectionLayout
                    step={CodeContainerV2.navigationOptions.step}
                    isModal={isModalOpen}
                >
                    <Title
                        titleFormatGowun="J’identifie"
                        titleFormatLexand={titleFormatted}
                    />
                    <Typography className="subtitle">
                        {subTitleFormatted}
                    </Typography>
                    <div className="inputs">
                        {energyTypes.map(it => (
                            <LabeledInput
                                key={it}
                                value={state[it]}
                                messages={{
                                    error:
                                        showError &&
                                        !mainState.loading &&
                                        errorFields[it],
                                }}
                                label={Wording.placeholder[it]}
                                onChange={v => this.handleChange(it, v)}
                                onFocus={() =>
                                    this.setState({ showError: false })
                                }
                                type="text"
                            />
                        ))}
                    </div>
                    <CustomCheckbox
                        variant={CheckBoxVariant.V2}
                        defaultValue={rgpd}
                        id="1"
                        onChange={v => this.handleChange('rgpd', v)}
                    >
                        <Typography fontSize={FontSizeVariant.SMALL}>
                        J’autorise expressément Wekiwi à récupérer auprès des gestionnaires des réseaux ma puissance souscrite et mon option tarifaire, mon historique de consommation (CAR) ainsi que mon profil et mon tarif, nécessaires à l'exécution de mon contrat, ainsi que mes données de consommation annuelles (si je suis équipé d'un compteur communicant - type Linky et Gazpar) me permettant de bénéficier d’un meilleur suivi de consommation. Cette autorisation prend effet aujourd'hui et durera pour les sept prochains jours afin de te permettre de finaliser ta souscription plus tard. Si tu ne finalises pas ta souscription, ces données ne seront pas sauvegardées.
                        </Typography>
                    </CustomCheckbox>
                    <CustomButton
                        disabledBackgroundColor="#f3f3f3"
                        arrowRight
                        lowPadding
                        title="Je continue ma souscription"
                        loading={mainState.loading}
                        color="orange"
                        disabled={!this.checkFirstModuleCondition()}
                        onClick={() => this.validate(firstAddress.postalCode)}
                    />

                    <DividerDashOr />

                    <Title
                        titleFormatGowun="Je n'ai pas"
                        titleFormatLexand={noCodeFormatted}
                    />
                    <Typography className="subtitle">
                        Si tu n’as pas accès à {noCodeSubFormatted}{' '}
                        immédiatement, simule ta consommation afin d’avoir une
                        estimation des forfaits qui te correspondraient au
                        mieux.
                    </Typography>
                    <CustomButton
                        title="Je fais une simulation"
                        color="orange"
                        arrowRight
                        lowPadding
                        onClick={this.onClickButtonSimulation}
                    />
                </SectionLayout>
                {currentMode === CodeScreenMode.OFFERS && (
                    <>
                        <div ref={this.myRefOffers} />
                        <SectionLayout
                            step={CodeContainerV2.navigationOptions.step}
                        >
                            <OfferSelector onChange={this.saveData} />
                        </SectionLayout>
                    </>
                )}
                {currentMode === CodeScreenMode.SIMULATION && (
                    <SurveyScreen
                        isCurrentScreen={isCurrentScreen}
                        setCanGoNext={this.handleSimilationValidate}
                        canGoNext={canGoNext}
                        nextScreen={nextScreen}
                        loading={mainState.loading}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    packageState: state.packageReducer,
    summaryState: state.summaryReducer,
    dataState: state.dataReducer,
    codeState: state.codeReducer,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    validateCodeScreenDispatch: payload =>
        dispatch(validateCodeScreen(payload)),
    validatePackageScreenDispatch: payload =>
        dispatch(validatePackageScreen(payload)),
    changeCodeModeDispatch: payload => dispatch(changeCodeMode(payload)),
    resetFullCodeScreenDispatch: () => dispatch(resetFullCodeScreen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeContainerV2);
