import React, { useEffect, useState } from 'react';
import WordingConstant from '../../../utils/wording.json';
import CustomSelectV2 from '../../CustomSelect/CustomSelectV2';

const Wording = WordingConstant.DataScreen;

const ChangeSupplierSelectComponent = ({
    dateContract,
    handleDropdownContract,
    energy,
}) => {
    const defaultValue = 'LATER';
    const [currentValue, setCurrentValue] = useState(defaultValue);

    useEffect(() => {
        if (dateContract.switch && dateContract.switch.value)
            setCurrentValue(dateContract.switch.value);
    }, [dateContract.switch]);

    return (
        <div className="date-container">
            <div
                className="date-container__switch-container"
                style={{ margin: 0 }}
            >
                 <p>Pour pouvoir te donner une date d’activation de ton contrat, indique-nous d’abord quel <span>moyen de paiement</span> tu souhaites utiliser pour ton paiement d’activation.</p>
           
                <CustomSelectV2
                    id={`idChangeSupplierCustomSelect-${energy}`}
                    options={Wording.contractDate.dropdownValues}
                    handleChange={handleDropdownContract}
                    value={currentValue}
                    placeholder={Wording.contractDate.placeholderSelector}
                    isLocked={false}
                    onMounSetter
                    defaultValue={defaultValue}
                    customSize="400px"
                />
            </div>
        </div>
    );
};

export default ChangeSupplierSelectComponent;
