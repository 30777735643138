import React, { useState, useEffect } from 'react';
import WordingConstant from '../../utils/wording.json';

import Title from '../../components/Typography/Title';
import Typography from '../../components/Typography/Typography';
import GasIcon from '../../assets/icons/ic-gas-circle-on.png';
import ElecIcon from '../../assets/icons/ic-elec-circle-on.png';
import { CustomAccordion, CustomCalendar } from '../../components';
import ChangeSupplierSelectComponent from '../../components/Situation/ChangeSupplierSelectComponent/ChangeSupplierSelectComponent';
import { checkIsDisableSwitchSelect } from '../../utils/helpers';
import { SituationTypes } from '../../constants/enums';
import { addDays, format } from 'date-fns';
import { fr } from 'date-fns/locale';

const Wording = WordingConstant.SituationScreen;
const WordingDate = WordingConstant.DataScreen;

const SituationChangeSupplierContainer = ({
    // setting,
    energyTypes,
    dateContract,
    handleDatePicker,
    handleDateCalendarOption,
    handleDropdownContract,
}) => {
    useEffect(() => {
        const getSwitchLater = () => WordingDate.contractDate.dropdownValues[1];

        if (checkIsDisableSwitchSelect(SituationTypes.SWITCH)) {
            handleDropdownContract(getSwitchLater());
        }
    }, [handleDropdownContract]);
    const defaultValue = 'NOW';
    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [dateChoosen, setdateChoosen] = useState();

    useEffect(() => {
        if (dateContract.switch && dateContract.switch.value)
            setCurrentValue(dateContract.switch.value);
    }, [dateContract.switch]);

    return (
        <div className="situation-container">
            {energyTypes.map(energy => {
                const currentDate = dateContract[energy].date
                    ? new Date(
                        dateContract[energy].year,
                        dateContract[energy].month,
                        dateContract[energy].date
                    )
                    : undefined;
                return (
                    <>
                        <div
                            className="situation-container__element"
                            key={`situation-container__element-${energy}`}
                        >

                            <div className="situation-container__element__select">
                                <ChangeSupplierSelectComponent
                                    dateContract={dateContract}
                                    handleDropdownContract={handleDropdownContract}
                                    energy={energy}
                                />
                            </div>
                        </div>

                        <div
                            className="situation-container__element"
                            key={`situation-container__element-${energy}`}
                        >
                            {
                                currentValue === "NOW" ? (
                                    <Title
                                        className="situation-container__element__header"
                                        titleFormatGowun={Wording[energy].titleContainer.text}
                                        titleFormatLexand={Wording[energy].titleContainer.suffix}
                                        icon={energy === 'EL' ? ElecIcon : GasIcon}
                                    />
                                ) : (
                                    <Title
                                        className="situation-container__element__header"
                                        titleFormatGowun={Wording[energy].titleContainer.textCHF}
                                        titleFormatLexand={Wording[energy].titleContainer.suffix}
                                        icon={energy === 'EL' ? ElecIcon : GasIcon}
                                    />
                                )
                            }

                            <div
                                style={{ marginBottom: '15px' }}
                            >
                                <CustomAccordion title="Le petit cours de Kiwi : C’est quoi le paiement d’activation ?">
                                    <Typography
                                        className="situation-container__element__payment"
                                        style={{ margin: 0, marginTop: '10px' }}
                                    >
                                        Le paiement d’activation correspond à ta
                                        <span style={{ fontWeight: 500 }}>
                                            {' '}
                                            1ère échéance de prépaiement.
                                        </span>
                                        <br />
                                        Tu peux choisir la date qui te convient le
                                        mieux.
                                        <br />
                                        <br />
                                        Tes autres échéances seront prélevés
                                        <span style={{ fontWeight: 500 }}>
                                            {' '}
                                            le 5 du mois
                                        </span>{' '}
                                        selon la fréquence de prépaiement que tu as
                                        choisie (tu pourras modifier cette date plus
                                        tard).
                                    </Typography>
                                </CustomAccordion>
                            </div>
                            <div className="situation-container__element__calendar">
                                <CustomCalendar
                                    currentDate={currentDate}
                                    handleOnSelectedDays={() =>
                                        handleDateCalendarOption(energy, 'selected')
                                    }
                                    handleOnDisabledDays={() =>
                                        handleDateCalendarOption(energy, 'disabled')
                                    }
                                    handleInputOnFocus={() => {
                                        this.handleChangeBool(energy, 'open');
                                        this.handleChangeBoolSelect(energy);
                                    }}
                                    handleOnDayChange={day => {
                                        handleDatePicker(day, energy)
                                        setdateChoosen(day)
                                    }
                                    }
                                    handleOnDayPickerHide={() =>
                                        this.handleChangeBool(energy, 'close')
                                    }
                                />
                            </div>
                            {currentValue === 'LATER' && (
                                <p>
                                    Je demande expressément à Wekiwi d&apos;être activé avant
                                    l&apos;expiration de mon délai de rétractation. Je conserve
                                    toutefois mon délai de rétractation de 14 jours à compter de
                                    la souscription du contrat. Si je me rétracte, je serai
                                    redevable des frais de l&apos;énergie consommée dans mon
                                    logement et cela mettra un terme à mon contrat. En cas de
                                    rétractation, il vous appartient de souscrire une offre
                                    alternative de votre choix pour éviter une interruption de
                                    fourniture d&apos;énergie.
                                </p>
                            )}
                            {/* <Title
                                className="situation-container__element__payment"
                                titleFormatGowun={
                                    Wording[energy].titleContainer.subtitleCHF
                                }
                            /> */}
                            {
                                currentValue === "NOW" ? (
                                    <>
                                        <Title
                                            style={{ marginTop: '25px' }}
                                            titleFormatGowun={Wording[energy].titleContainer.subtitle}
                                        />
                                        <Typography className="situation-container__element__payment">
                                            Le paiement d'activation se fait par 
                                            <span> carte bancaire ou via connexion à ta banque</span> juste après ta souscription pour accélérer ton activation.
                                        </Typography>
                                    </>
                                ) : (
                                    dateChoosen && (
                                        <>
                                            <Title
                                                className="situation-container__element__payment"
                                                titleFormatGowun={Wording[energy].titleContainer.subtitleCHF}
                                            />
                                            <p>
                                                Avec la date de paiement d’activation que tu viens de choisir, l’activation de ton contrat gaz est prévue le : <span>{format(addDays(dateChoosen, 21), "dd/MM/yyyy", fr)}.</span> Cette date n’est qu’indicative.
                                            </p>
                                        </>
                                    )
                                )
                            }


                        </div>
                    </>
                );
            })}
        </div>
    );
};

export default SituationChangeSupplierContainer;
