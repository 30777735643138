import React from 'react';

import WordingConstant from '../../utils/wording.json';
import { additionalRatesDuplicateValue } from '../../utils/helpers';

import iconElecCircleOn from '../../assets/icons/ic-elec-circle-on.png';
import iconGasCircleOn from '../../assets/icons/ic-gas-circle-on.png';

const PackageWording = WordingConstant.PackageScreen;
const SummaryWording = WordingConstant.SummaryScreen;
const CommonWording = WordingConstant.Common;

const SummaryElement = ({
    offer,
    paymentFrequency,
    // loadingOption,
    isScheduleWithoutPack,
}) => {
    const frequency =
        PackageWording.frequency.values &&
        (
            PackageWording.frequency.values.find(
                v => v.value === paymentFrequency
            ) || {}
        ).text;
    const details = offer.details || [];
    const totalAmount = details.find(
        d => d.category === SummaryWording.detail.category.totalAmount
    );
    const additionalRates = additionalRatesDuplicateValue(
        details.filter(
            d => d.category === SummaryWording.detail.category.additionalRate
        )
    );
    const totalBudget = details.find(
        d => d.category === SummaryWording.detail.category.totalBudget
    );
    const economy = details.find(
        d => d.category === SummaryWording.detail.category.economy
    );

    const renderPrice = ({ label, amount, unit }, index) => {
        const displayedUnit = CommonWording.unit[unit];
        return (
            <p
                style={{ lineHeight: '20px' }}
                key={index}
                className="summary-element__value"
            >
                {label} {index === 101 ? '(TTC) : ' : ' : '}
                <span>
                    {amount} {displayedUnit}
                </span>
            </p>
        );
    };

    return (
        <div className="summary-element">
            <div style={{ position: 'absolute', right: 15, top: 30 }}>
                <img
                    className="icon"
                    src={
                        offer.energy === 'NG'
                            ? iconGasCircleOn
                            : iconElecCircleOn
                    }
                    alt="icon energy"
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p style={{ marginBottom: '32px' }}>
                            <span className="mainText">
                                {' '}
                                {paymentFrequency === ''
                                    ? PackageWording.frequency.titleCyclic
                                    : PackageWording.frequency.title}
                            </span>{' '}
                            <span>{frequency}</span>
                        </p>

                        <React.Fragment>
                            <h3
                                style={{
                                    fontSize: '1.2rem',
                                    fontWeight: 500,
                                    margin: 0,
                                    marginBottom: '16px',
                                }}
                            >
                                {SummaryWording.detail.budget.title}
                            </h3>
                            {totalAmount && renderPrice(totalAmount, 100)}
                            {additionalRates.map((d, index) =>
                                renderPrice(d, index)
                            )}
                            {totalBudget && renderPrice(totalBudget, 101)}
                        </React.Fragment>
                    </div>
                </div>
                <div style={{ marginTop: '16px' }}>
                    {economy && economy.amount > 0 && (
                        <React.Fragment>
                            <p>
                                {SummaryWording.detail.budget.estimatedEconomy}
                            </p>
                            {renderPrice(economy, 102)}
                        </React.Fragment>
                    )}

                    {!isScheduleWithoutPack && (
                        <p className="summary-element__description">
                            {SummaryWording.detail.budget.description}
                        </p>
                    )}

                    {economy && economy.amount > 0 && (
                        <p className="summary-element__description">
                            {SummaryWording.detail.budget.notice}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SummaryElement;
