import React, { createRef } from 'react';
import { connect } from 'react-redux';

import SectionLayout from '../../components/SectionLayout/SectionLayout';
import SummaryContainer from '../../containers/SummaryContainer';
import { logScreen } from '../../utils/firebase';
import { isScheduleWithoutPackBillingMode } from '../../utils/helpers';
import { TagLogger, TriggerButtonLogger } from '../../utils/logger';
import WordingConstant from '../../utils/wording.json';
import Screen from '../screen';
import * as actions from './SummaryScreen.actions';
import { CustomButton } from '../../components';
import { scrollToRefDelay } from '../../utils/scroll';
import Title from '../../components/Typography/Title';

const Wording = WordingConstant.SummaryScreen;

export class SummaryScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.DATA,
        previousScreen: Screen.PACKAGE,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.SUMMARY,
        showSaveButton: false,
        step: 2,
        validate: () => {},
    };

    constructor(props) {
        super(props);
        this.myRef = createRef();
    }

    componentDidMount() {
        logScreen(TagLogger.SUMMARY);

        SummaryScreen.navigationOptions.validate = () => {
            const { validateSummaryScreenDispatch } = this.props;
            validateSummaryScreenDispatch({});
            return true;
        };

        const { handleGoNext } = this.props;
        if (handleGoNext) {
            handleGoNext(true);
        }
        scrollToRefDelay(this.myRef, 200);
    }

    componentDidUpdate() {
        const {
            setCanGoNext,
            isCurrentScreen,
            mainState: { loading },
        } = this.props;

        if (setCanGoNext && isCurrentScreen && !loading) {
            setCanGoNext(true);
        }
    }

    /**
     * Function to handle toggle in multiple choice products
     * options save offer.product.productCode
     */
    handleToggle = ({ target: { value, checked } }, type) => {
        const {
            userState: { userType },
            summaryState,
            changeOfferDispatch,
            changeProductOptionDispatch,
            resetScreenTo,
            isCurrentScreen,
        } = this.props;
        if (!isCurrentScreen) resetScreenTo();
        const offer = summaryState.offers.find(
            off =>
                off.energy === type &&
                (checked ? !off.product.default : off.product.default)
        );
        if (offer) {
            const options = checked ? [value] : [];
            changeOfferDispatch({
                offer,
                options,
                energyType: type,
            });
        } else {
            const productCode = checked
                ? value
                : (
                      summaryState.products.find(
                          p => p.energy === type && p.default
                      ) || {}
                  ).productCode || '';
            changeProductOptionDispatch({
                ...summaryState,
                userType,
                productCode,
                energyType: type,
                contracts: summaryState.contracts.filter(
                    p => p.energy === type
                ),
                logger: {
                    tag: TagLogger.SUMMARY,
                    triggerButton: TriggerButtonLogger.FIXED_PRICE,
                },
            });
        }
    };

    render() {
        const {
            userState: { energyTypes },
            summaryState,
            autorizedBillingModes,
            changeOfferDispatch,
            changeProductOptionDispatch,
            canGoNext,
            nextScreen,
            isCurrentScreen,
            mainState: { loading },
            isModalOpen,
        } = this.props;
        const isScheduleWithoutPack = isScheduleWithoutPackBillingMode(
            autorizedBillingModes
        );
        let packageLoading = false;
        if (energyTypes) {
            energyTypes.forEach(energy => {
                const s = summaryState[energy];
                if (s.loadingOption) {
                    packageLoading = true;
                }
            });
        }
        return (
            <>
                <div ref={this.myRef} />
                <SectionLayout step={5} isModal={isModalOpen}>
                    <Title
                        titleFormatGowun="Mon estimation de"
                        titleFormatLexand="budget énergétique"
                    />
                    <div className="summary-screen">
                        {energyTypes &&
                            energyTypes.map((energy, index) => {
                                const s = summaryState[energy];
                                const offer = s.offer;
                                const options = s.options;
                                const prices = s.chosenPackages
                                    ? s.chosenPackages.map(
                                          p => (p || {}).amount
                                      )
                                    : [];
                                const products = summaryState.products
                                    ? summaryState.products.filter(
                                          product =>
                                              !product.default &&
                                              product.energy === energy
                                      )
                                    : [];
                                const defaultProduct = summaryState.products
                                    ? summaryState.products.filter(
                                          product =>
                                              product.default &&
                                              product.energy === energy
                                      )
                                    : [];
                                const consumption = s.chosenPackages
                                    ? s.chosenPackages.map(
                                          p => (p || {}).quantityMonthly
                                      )
                                    : [];
                                return (
                                    <SummaryContainer
                                        key={index}
                                        prices={prices}
                                        paymentFrequency={
                                            s.installmentFrequency
                                        }
                                        consumption={consumption}
                                        offer={offer}
                                        products={products}
                                        currentOptions={options}
                                        changeProductOptionDispatch={
                                            changeProductOptionDispatch
                                        }
                                        changeOfferDispatch={
                                            changeOfferDispatch
                                        }
                                        handleToggle={e =>
                                            this.handleToggle(e, energy)
                                        }
                                        loadingOption={s.loadingOption}
                                        loading={loading}
                                        energy={energy}
                                        showSeparator={
                                            index + 1 < energyTypes.length
                                        }
                                        isScheduleWithoutPack={
                                            isScheduleWithoutPack
                                        }
                                        defaultProduct={defaultProduct}
                                    />
                                );
                            })}
                    </div>
                    {isCurrentScreen && (
                        <div className="validate-button-top-space">
                            <CustomButton
                                disabledBackgroundColor="#f3f3f3"
                                title="Valider"
                                color="orange"
                                disabled={!canGoNext}
                                onClick={nextScreen}
                                loading={loading || packageLoading}
                            />
                        </div>
                    )}
                </SectionLayout>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    summaryState: state.summaryReducer,
    autorizedBillingModes: state.packageReducer.autorizedBillingModes,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    validateSummaryScreenDispatch: payload =>
        dispatch(actions.validateSummaryScreenSuccess(payload)),
    changeProductOptionDispatch: payload =>
        dispatch(actions.changeProductOption(payload)),
    changeOfferDispatch: payload => dispatch(actions.changeOffer(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryScreen);
