import React from 'react';
import WordingConstant from '../../utils/wording.json';

import Title from '../../components/Typography/Title';
import Typography from '../../components/Typography/Typography';
import GasIcon from '../../assets/icons/ic-gas-circle-on.png';
import ElecIcon from '../../assets/icons/ic-elec-circle-on.png';
import resources from '../../utils/resources';
import {
    CustomAccordion,
    CustomCalendar,
    TimeSlotComponent,
} from '../../components';
import LoadingOverlay from '../../components/LoadingOverlay';

const Wording = WordingConstant.SituationScreen;

const SituationMovedContainer = ({
    // setting,
    energyTypes,
    dateContract,
    handleDatePicker,
    handleChangeTimeslotSelect,
    handleDateCalendarOption,
}) => (
    <div className="situation-container">
        {energyTypes.map(energy => {
            const currentDate = dateContract[energy].date
                ? new Date(
                      dateContract[energy].year,
                      dateContract[energy].month,
                      dateContract[energy].date
                  )
                : undefined;
            return (
                <div
                    className="situation-container__element"
                    key={`situation-container${energy}`}
                >
                    <Title
                        className="situation-container__element__header"
                        titleFormatGowun={Wording[energy].titleContainer.text}
                        titleFormatLexand={
                            Wording[energy].titleContainer.suffix
                        }
                        icon={energy === 'EL' ? ElecIcon : GasIcon}
                    />

                    <Typography className="situation-container__element__title">
                        <span> Attention ! </span> Les dates et créneaux
                        horaires sont
                        <span> indicatifs </span> et non définitifs, <br />
                        le gestionnaire de réseau est le seul à pouvoir fixer la
                        date de l’intervention.
                    </Typography>

                    <div className="situation-container__element__calendar">
                        <CustomCalendar
                            currentDate={currentDate}
                            handleOnSelectedDays={() =>
                                handleDateCalendarOption(energy, 'selected')
                            }
                            handleOnDisabledDays={() =>
                                handleDateCalendarOption(energy, 'disabled')
                            }
                            handleInputOnFocus={() => {
                                this.handleChangeBool(energy, 'open');
                                this.handleChangeBoolSelect(energy);
                            }}
                            handleOnDayChange={day =>
                                handleDatePicker(day, energy)
                            }
                            handleOnDayPickerHide={() =>
                                this.handleChangeBool(energy, 'close')
                            }
                        />
                    </div>

                    <div className="situation-container__element__calendar__details">
                        Les dates avec un{' '}
                        <img
                            className="day-warning"
                            alt="warning"
                            src={resources['ic-warning']}
                        />{' '}
                        entraînent un surcoût.
                    </div>
                    <LoadingOverlay
                        minHeight="128px"
                        loading={
                            (!dateContract[energy].timeslots ||
                                dateContract[energy].timeslots.length === 0) &&
                            currentDate !== undefined
                        }
                    >
                        <div>
                            <TimeSlotComponent
                                energy={energy}
                                dateContract={dateContract}
                                handleChangeTimeslotSelect={
                                    handleChangeTimeslotSelect
                                }
                            />
                        </div>
                    </LoadingOverlay>
                    <Title
                        style={{ marginTop: '25px' }}
                        titleFormatGowun={
                            Wording[energy].titleContainer.subtitle
                        }
                    />
                    <Typography className="situation-container__element__payment">
                    Dans le cadre d'une mise en service, le paiement d'activation se fait par <br />
                        <span> carte bancaire ou via connexion à ta banque </span> juste après ta souscription pour accélérer ton activation.
                    </Typography>
                    <CustomAccordion title="Le petit cours de Kiwi : C’est quoi le paiement d’activation ?">
                        <Typography
                            className="situation-container__element__payment"
                            style={{ margin: 0, marginTop: '10px' }}
                        >
                            Le paiement d’activation correspond à ta
                            <span style={{ fontWeight: 500 }}>
                                {' '}
                                1ère échéance de prépaiement.
                            </span>
                            <br />
                            Tu peux choisir la date qui te convient le mieux.
                            <br />
                            <br />
                            Tes autres échéances seront prélevées
                            <span style={{ fontWeight: 500 }}>
                                {' '}
                                le 5 du mois
                            </span>{' '}
                            selon la fréquence de prépaiement que tu as choisie
                            (tu pourras modifier cette date plus tard).
                        </Typography>
                    </CustomAccordion>
                </div>
            );
        })}
    </div>
);
export default SituationMovedContainer;
