import React, { createRef } from 'react';
import { connect } from 'react-redux';

import accents from 'remove-accents';
import Screen from '../screen';
import SearchService from '../../utils/search';
import * as actions from './DataScreen.actions';
import * as helpers from '../../utils/helpers';
import Config from '../../utils/config';
import { searchSiret, searchAppointmentTimeslot } from '../../utils/apiRequest';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import Title from '../../components/Typography/Title';
import Typography from '../../components/Typography/Typography';
import LabeledInput from '../../components/LabeledInput/LabeledInput';
import CustomSelectV2 from '../../components/CustomSelect/CustomSelectV2';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomDate from '../../components/CustomDate';
import './_DataScreen.scss';
import WordingConstant from '../../utils/wording.json';
import { renderValueDate } from '../../components/CustomDate/CustomDate';
import { TagLogger, TriggerButtonLogger } from '../../utils/logger';
import { logScreen } from '../../utils/firebase';
import { SituationTypes } from '../../constants/enums';
import countries from '../../utils/countries.json';
import CustomToggleV2 from '../../components/CustomToggle/CustomToggleV2';
import { validateCodeScreen } from '../CodeScreen/CodeScreen.actions';
import { scrollToRefDelay } from '../../utils/scroll';
import { validateSurveyScreen } from '../SurveyScreen/SurveyScreen.actions';

const countriesOptions = countries.map(cntr => ({
    value: cntr.name.toUpperCase(),
    label: `${cntr.name}`,
    id: `${cntr.name}`,
}));

const Wording = WordingConstant.DataScreen;
const UserScreenWording = WordingConstant.UserScreen;
const CodeScreenWording = WordingConstant.CodeScreen;
const AddressContainerWording = WordingConstant.AddressContainer;

export class DataScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.FINALSUMMARY,
        previousScreen: Screen.SUMMARY,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.DATA,
        step: 6,
        validate: () => {},
        disabled: () => {},
        returnButton: () => {},
        saveData: () => {},
    };

    constructor(props) {
        super(props);
        const {
            dataState,
            summaryState: { contracts },
        } = props;
        const { address } = contracts[0].deliveryPoint;
        let currentAddress;
        const { common, part } = dataState;
        if (address && address.address) {
            let fullAddress = `${address.street || ''}`;
            if (address.number) {
                fullAddress = `${address.number} ${fullAddress}`;
            }
            currentAddress = {
                address: fullAddress,
                postalCode: address.postalCode || '',
                city: address.townName || '',
                netArea: address.netArea,
            };
        } else {
            currentAddress = common.address;
        }
        const checkedNewAddress =
            common.newAddress &&
            (common.newAddress.address ||
                common.newAddress.postalCode ||
                common.newAddress.city);
        const checkedAddCoOwner =
            part.coOwner &&
            (part.coOwner.firstName !== '' || part.coOwner.lastName !== '');
        const checkedNewBillingAddress =
            common.newBillingAddress &&
            (common.newBillingAddress.name ||
                common.newBillingAddress.surname ||
                common.newBillingAddress.address ||
                common.newBillingAddress.postalCode ||
                common.newBillingAddress.city);

        this.state = {
            fields: {
                ...dataState,
                common: {
                    ...dataState.common,
                    address: currentAddress,
                },
            },
            EL: '',
            NG: '',
            nonExistingForm: {},
            setting: {
                checkedAddCoOwner,
                checkedNewAddress: checkedNewAddress !== null,
                checkedNewBillingAddress:
                    checkedNewBillingAddress !== null &&
                    checkedNewBillingAddress !== '',
                postalCodes: [],
                isLoadingPC: false,
                codeText: '',
                codeNewAddressText: '',
                codeNewBillingAddressText: '',
                codeBirthTown: '',
                checkError: {
                    birthdate: false,
                    birthCountry: false,
                    birthPostalCode: false,
                    birthCity: false,
                    SIRET: false,
                    APECode: false,
                    socialReason: false,
                    legalForm: false,
                },
                showError: false,
                showEmailError: false,
                showBottomText: false,
                validSiret: null,
                resetEstimate: false,
            },
            errorFields: {
                EL: undefined,
                NG: undefined,
            },
        };

        this.searchService = new SearchService(TagLogger.DATA);
        this.myRef = createRef();
    }

    componentDidMount() {
        logScreen(TagLogger.DATA);

        this.initBirthCountry();
        scrollToRefDelay(this.myRef, 200);

        const { setting, fields } = this.state;

        const {
            validateDataScreenDispatch,
            validateDataScreenFinalDispatch,
            userState: { energyTypes },
            codeState: { PDL, PCE },
            dataState,
        } = this.props;
        this.searchService.getResults().subscribe(res => {
            if (res.length > 0) {
                setting.postalCodes = res.map(dt => ({
                    id: dt.code,
                    label: `${dt.code} - - - ${dt.city}`,
                    value: `${dt.code}__${dt.city}`,
                    codeValue: dt.code,
                    cityValue: dt.city,
                    netAreaValue: dt.netArea,
                }));
            } else {
                setting.postalCodes = [];
            }
            setting.isLoadingPC = false;
            this.setState({ setting });
        });
        // DataScreen.navigationOptions.validate = this.handleCondition;
        DataScreen.navigationOptions.validate = () => {
            const modifiedFields = {
                ...this.prepareField(fields),
                logger: {
                    tag: TagLogger.DATA,
                    triggerButton: TriggerButtonLogger.VALIDATE_BTN,
                },
            };
            const reducer = (accumulator, currentValue) =>
                accumulator &&
                (currentValue === 'EL' ? PDL.length === 0 : PCE.length === 0);
            const condition = energyTypes.reduce(reducer, true);
            if (condition) {
                return validateDataScreenFinalDispatch(modifiedFields);
            }
            return validateDataScreenDispatch(modifiedFields);
        };

        DataScreen.navigationOptions.disabled = () => {
            setting.showError = true;
            this.setState({ setting });
        };

        DataScreen.navigationOptions.returnButton = () => {
            const { dataScreenSaveCommonDispatch } = this.props;
            dataScreenSaveCommonDispatch({ ...fields.common });
        };

        DataScreen.navigationOptions.saveData = () => {
            if (this.handleCondition()) {
                const { userState, summaryState, paymentState } = this.props;
                return {
                    ...userState,
                    ...fields,
                    ...summaryState,
                    ...paymentState,
                    logger: {
                        tag: TagLogger.DATA,
                        triggerButton: TriggerButtonLogger.SAVE_BTN,
                    },
                };
            }
            setting.showError = true;
            this.setState({ setting });
            return null;
        };

        this.handleCondition();

        const {
            userState,
            fetchCalendarAppointmentDispatch,
            summaryState,
        } = this.props;
        fetchCalendarAppointmentDispatch({
            ...userState,
            ...summaryState,
        });

        const { common } = dataState;

        energyTypes.forEach(energy => {
            if (common.dateContract[energy].timeslot) {
                const newDate = new Date(
                    common.dateContract[energy].year,
                    common.dateContract[energy].month,
                    common.dateContract[energy].date
                );
                this.sendRequestTimeslot(newDate, energy);
            }
        });

        if (fields.pro.SIRET) {
            const identificationNumber = fields.pro.SIRET.replace(
                /[^\d ]/g,
                ''
            );
            if (identificationNumber.length === 14) {
                this.searchSiretRequest(identificationNumber);
            }
        }
    }

    componentDidUpdate() {
        this.handleCondition();
    }

    componentWillUnmount() {
        this.searchService.unsubscribe();
    }

    prepareField = fields => {
        const {
            setting: {
                checkedAddCoOwner,
                checkedNewAddress,
                checkedNewBillingAddress,
            },
        } = this.state;
        const {
            userState: { energyTypes },
        } = this.props;
        const modifiedFields = { ...fields, energyTypes };

        if (!checkedAddCoOwner) {
            modifiedFields.part = {
                coOwner: {
                    civility: 'MR',
                    firstName: '',
                    lastName: '',
                },
            };
        }
        if (!checkedNewAddress) {
            modifiedFields.common.newAddress = {
                address: null,
                postalCode: null,
                city: null,
                netArea: null,
            };
        }
        if (!checkedNewBillingAddress) {
            modifiedFields.common.newBillingAddress = {
                civility: 'MR',
                name: null,
                surname: null,
                address: null,
                postalCode: null,
                city: null,
                netArea: null,
            };
        }
        return modifiedFields;
    };

    initBirthCountry = () => {
        const { fields } = this.state;
        const params = helpers.searchParamsFromURL();

        let birthCountry = params.get('birthCountry');
        if (birthCountry) birthCountry = birthCountry.toUpperCase();

        const birthTown = params.get('birthTown');
        const postalCode = params.get('postalCode');

        if (birthCountry === 'FRANCE') {
            if (birthTown && postalCode && postalCode.length === 5) {
                fields.common.birthTown = {
                    ...fields.common.birthTown,
                    country: birthCountry,
                    townName: birthTown,
                    postalCode,
                };

                return this.setState({
                    fields,
                });
            }

            return null;
        }

        if (birthCountry && birthTown) {
            fields.common.birthTown = {
                ...fields.common.birthTown,
                country: birthCountry,
                townName: birthTown,
                postalCode: '',
            };

            return this.setState({
                fields,
            });
        }
        return null;
    };

    handleCondition = () => {
        const {
            setCanGoNext,
            userState,
            mainState: { loading },
            validateSurveyScreenDispatch,
            surveyState,
            packageState,
            summaryState,
        } = this.props;
        const { fields, setting } = this.state;
        const {
            dataState: {
                common: { address },
            },
        } = this.props;
        fields.common.address = address;
        if (
            address &&
            address.address &&
            address.address !== '' &&
            address.address !== fields.common.address.address
        ) {
            this.setState({ fields });
        }
        if (
            !loading &&
            setting.resetEstimate &&
            summaryState.contracts.length > 0 &&
            summaryState.contracts[0].estimates === undefined
        ) {
            validateSurveyScreenDispatch({
                ...surveyState,
                ...userState,
                missingEnergy: packageState.missingEnergy,
                loadEstimate: true,
                logger: {
                    tag: TagLogger.SURVEY,
                    triggerButton: TriggerButtonLogger.VALIDATE_BTN,
                },
            });
            setting.resetEstimate = false;
            this.setState({ setting });
        }
        const condition =
            userState.userType === UserScreenWording.userType.professional
                ? this.handleConditionPro()
                : this.handleConditionPart();
        if (setCanGoNext) {
            if (condition) setCanGoNext(true);
            else setCanGoNext(false);
            return condition;
        }
        return condition;
    };

    handleConditionPro = () => {
        const { fields, setting } = this.state;

        let condition = this.handleConditionCommon(fields.common);
        condition =
            condition &&
            this.handleConditionDataPro(fields.pro, setting.validSiret);
        if (setting.checkedAddCoOwner)
            condition =
                condition && this.handleConditionCoOwner(fields.part.coOwner);
        if (setting.checkedNewAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newAddress);
        if (setting.checkedNewBillingAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newBillingAddress);
        return condition;
    };

    handleConditionPart = () => {
        const {
            fields,
            setting: {
                checkedAddCoOwner,
                checkedNewAddress,
                checkedNewBillingAddress,
            },
        } = this.state;

        let condition =
            this.handleConditionCommon(fields.common) &&
            this.handleConditionAddress(fields.common.address);
        if (checkedAddCoOwner)
            condition =
                condition && this.handleConditionCoOwner(fields.part.coOwner);
        if (checkedNewAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newAddress);
        if (checkedNewBillingAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newBillingAddress);
        return condition;
    };

    handleConditionSituation = () => {
        const {
            fields: {
                common: { dateContract },
            },
        } = this.state;
        const {
            userState: { energyTypes },
            summaryState,
        } = this.props;
        const isSwitch = dateContract.switch;

        const reducerSwitch = (accumulator, energy) =>
            accumulator && !summaryState[energy].cyclicBill
                ? dateContract[energy] &&
                  dateContract[energy].date !== null &&
                  dateContract[energy].month !== null &&
                  dateContract[energy].year !== null
                : accumulator;

        const reducer = (accumulator, energy) =>
            accumulator &&
            dateContract[energy] &&
            dateContract[energy].date !== null &&
            dateContract[energy].month !== null &&
            dateContract[energy].year !== null &&
            (!dateContract.switch
                ? dateContract[energy].timeslot !== ''
                : true);

        return energyTypes.reduce(isSwitch ? reducerSwitch : reducer, true);
    };

    handleConditionCoOwner = ({ firstName, lastName }) =>
        firstName !== '' && lastName !== '';

    handleConditionAddress = ({ address, city, postalCode }) =>
        address !== '' &&
        address !== null &&
        city !== '' &&
        city !== null &&
        postalCode !== '' &&
        postalCode !== null;

    handleConditionCommon = ({
        birthdate,
        birthTown,
        civility,
        name,
        surname,
        email,
        tel,
    }) => {
        const { dataState } = this.props;
        const { accessToken } = dataState.logingInformation;
        return (
            civility !== '' &&
            civility !== undefined &&
            name !== '' &&
            surname !== '' &&
            (accessToken ||
                (birthTown.country !== '' &&
                    birthTown.townName !== '' &&
                    (!(birthTown.country === 'FRANCE') ||
                        birthTown.postalCode !== '') &&
                    birthdate !== '' &&
                    !helpers.birthCondition(birthdate))) &&
            email !== '' &&
            helpers.handleConditionEmail(email) &&
            tel !== null &&
            helpers.handleConditionPhone(tel)
        );
    };

    handleConditionDataPro = (
        { socialReason, legalForm, APECode, SIRET },
        validSiret
    ) =>
        socialReason !== '' &&
        legalForm !== '' &&
        APECode &&
        APECode.length !== '' &&
        SIRET &&
        SIRET.length === 14 &&
        validSiret === 200;

    handleEmailFormat = value => {
        const { setting } = this.state;
        const cleanValue = accents.remove(value);
        if (!helpers.handleConditionEmail(cleanValue)) {
            setting.showEmailError = true;
            this.setState({ setting });
            return cleanValue;
        }
        setting.showEmailError = false;
        this.setState({ setting });
        return cleanValue;
    };

    handleChangeInput = (value, name, type) => {
        const { fields } = this.state;
        const { isCurrentScreen, resetScreenTo } = this.props;
        if (!isCurrentScreen) resetScreenTo();
        let cleanValue = value;
        if (value.target !== undefined) cleanValue = value.target.value;
        if (name === null) {
            switch (type) {
                case 'email':
                    fields.common.email = this.handleEmailFormat(cleanValue);
                    break;
                case 'surname':
                case 'name':
                    fields.common[type] = cleanValue.replace(/[0-9]/g, '');
                    break;
                case 'tel':
                    fields.common.tel = cleanValue.replace(/[^\d ]/g, '');
                    break;
                case 'SIRET': {
                    fields.pro.SIRET = cleanValue.replace(/[^\d ]/g, '');
                    if (fields.pro.SIRET.length === 14)
                        this.searchSiretRequest(cleanValue);
                    if (cleanValue === '') fields.pro.APECode = '';

                    break;
                }
                case 'APECode':
                    fields.pro.APECode = cleanValue;
                    break;
                case 'socialReason':
                    fields.pro.socialReason = cleanValue;
                    break;
                case 'coOwner':
                    fields.part.coOwner[type] = cleanValue;
                    break;
                default:
                    fields.common[type] = cleanValue;
                    break;
            }
        } else if (name === 'coOwner') {
            fields.part.coOwner[type] = cleanValue;
        } else {
            fields.common[name][type] = cleanValue;
        }
        this.setState({ fields });
    };

    handleDateChange = date => {
        const { fields } = this.state;
        this.onFieldChange('birthdate', date, 'new');
        if (date !== undefined) {
            const bDate = renderValueDate(date);
            fields.common.birthdate = bDate.toDateString();
            this.setState({ fields });
        }
    };

    handleChangeSelect = ({ value }, nameObj, type, ob) => {
        const { fields } = this.state;
        const { isCurrentScreen, resetScreenTo } = this.props;
        if (!isCurrentScreen) resetScreenTo();
        if (ob === undefined) fields[nameObj][type] = value;
        else if (type === 'birthTown' && ob === 'country') {
            fields[nameObj].birthTown.country = value;
            fields[nameObj].birthTown.postalCode = '';
            fields[nameObj].birthTown.townName = '';
            fields[nameObj].birthTown.netArea = '';
        } else fields[nameObj][type][ob] = value;
        this.setState({ fields });
    };

    handleInputChangeAddressContainer = (value, type) => {
        const { setting } = this.state;
        setting[type] = value.replace(/[^\d ]/g, '');
        if (value !== '') {
            setting.isLoadingPC = true;
        }

        const val = value.length > 5 ? value.substring(0, 4) : value;
        this.searchService.search(val);
        this.setState({ setting });
    };

    onFocusDropdown = (e, type) => {
        const { setting, fields } = this.state;
        if (type === 'address') {
            if (fields.common.address.postalCode !== '') {
                setting.codeText = fields.common.address.postalCode.toString();
            }
        } else if (type === 'newAddress') {
            if (fields.common.newAddress.postalCode) {
                setting.codeNewAddressText = fields.common.newAddress.postalCode.toString();
            }
        } else if (type === 'newBillingAddress') {
            if (fields.common.newBillingAddress.postalCode) {
                setting.codeNewBillingAddressText = fields.common.newBillingAddress.postalCode.toString();
            }
        } else if (type === 'birthTown') {
            if (fields.common.birthTown.postalCode) {
                setting.codeBirthTown = fields.common.birthTown.postalCode.toString();
            }
        }
        this.setState({ setting });
    };

    handleChangeDropdownSelect = (object, type) => {
        const { fields, setting } = this.state;
        const { codeValue, cityValue, netAreaValue } = object;
        if (type === 'address') {
            fields.common.address.city = cityValue;
            fields.common.address.postalCode = codeValue;
            fields.common.address.netArea = netAreaValue;
            if (codeValue !== undefined)
                setting.codeText = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        } else if (type === 'newAddress') {
            fields.common.newAddress.city = cityValue;
            fields.common.newAddress.postalCode = codeValue;
            fields.common.newAddress.netArea = netAreaValue;
            if (codeValue !== undefined)
                setting.codeNewAddressText = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        } else if (type === 'newBillingAddress') {
            fields.common.newBillingAddress.city = cityValue;
            fields.common.newBillingAddress.postalCode = codeValue;
            fields.common.newBillingAddress.netArea = netAreaValue;
            if (codeValue !== undefined)
                setting.codeNewBillingAddressText = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        } else if (type === 'birthTown') {
            fields.common.birthTown.townName = cityValue;
            fields.common.birthTown.postalCode = codeValue;
            fields.common.birthTown.netArea = netAreaValue;
            fields.common.birthTown.country = 'FRANCE';
            if (codeValue !== undefined)
                setting.codeBirthTown = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        }
    };

    handleDisplayValue = (value, type) => {
        switch (type) {
            case 'tel':
            case 'SIRET':
                return value.replace(/[^\d ]/g, '');

            case 'name':
                return value.replace(/[0-9]/g, '');

            default:
                return value;
        }
    };

    handleToggle = ({ target: { checked } }, type) => {
        const { setting, fields } = this.state;
        const { isCurrentScreen, resetScreenTo } = this.props;
        if (!isCurrentScreen) resetScreenTo();
        if (type === 'checkedNewAddress') {
            setting.checkedNewAddress = setting.checkedNewAddress !== checked;
        } else {
            setting[type] = !setting[type];
        }
        this.setState({ setting, fields });
    };

    handleToggleSituation = ({ target: { value } }, type) => {
        const { fields, setting } = this.state;
        setting.showBottomText = false;
        fields.common[type] = value;
        const defaultValue = {
            date: null,
            month: null,
            year: null,
            dateEffectiveStartDate: null,
            monthEffectiveStartDate: null,
            yearEffectiveStartDate: null,
            timeslot: '',
            timeslots: [],
        };
        fields.common.dateContract = {
            EL: { ...defaultValue },
            NG: { ...defaultValue },
            switch:
                value === SituationTypes.MOVE_IN
                    ? null
                    : fields.common.dateContract.switch,
        };

        this.setState({ fields, setting });
    };

    handleDatePicker = (date, type) => {
        const { fields, setting } = this.state;

        const { summaryState } = this.props;
        const day = Config.ChannelsDoorToDoor.includes(localStorage.getItem("channel")) ? 7 : 3;
        const featureDate = helpers.addBusinessDays(day);
        const newDate = new Date(date);

        const isSwitch = fields.common.dateContract.switch;

        if (fields.common.situation === Wording.situation.values.moved.value)
            setting.showBottomText = newDate.getTime() <= featureDate.getTime();
        if (newDate.getTime() > new Date().getTime()) {
            this.sendRequestTimeslot(newDate, type);
            const {
                dataState: { calendarAppointments },
            } = this.props;
            fields.common.dateContract[type].date = newDate.getDate();
            fields.common.dateContract[type].year = newDate.getFullYear();
            fields.common.dateContract[type].month = newDate.getMonth();
            const daysList = (
                calendarAppointments.find(c => c.energy === type) || {
                    daysList: [],
                }
            ).daysList;
            const formattedDate = newDate.toISOString().split('T')[0];
            const express = (
                daysList.find(d => d.date === formattedDate) || {
                    express: false,
                }
            ).express;

            const defaultCB = {
                firstPaymentCB: false,
            };
            const firstPaymentCB = (!isSwitch
                ? daysList.find(d => d.date === formattedDate) || defaultCB
                : defaultCB
            ).firstPaymentCB;

            fields.common.dateContract[type].express = express;
            fields.common.dateContract[type].firstPaymentCB = !summaryState[
                type
            ].cyclicBill
                ? firstPaymentCB
                : false;

            this.setState({ fields, setting });
        }
    };

    sendRequestTimeslot = async (date, type) => {
        const { fields } = this.state;
        const ts = await searchAppointmentTimeslot(date, type);
        fields.common.dateContract[type].timeslots = ts.filter(
            t => t !== 'NONE'
        );
        this.setState({ fields });
    };

    searchSiretRequest = async value => {
        const { setting, fields } = this.state;
        const res = await searchSiret(value);
        if (res) {
            if (res.etablissement) {
                fields.pro.APECode = helpers.getAPEFromObject(res);
            }
            setting.validSiret = res.status;
            this.setState({ setting, fields });
        }
    };

    handleChangeTimeslotSelect = (e, type) => {
        const { fields } = this.state;
        fields.common.dateContract[type].timeslot = e.value;
        this.setState({ fields });
    };

    filterCalendarAppointmentResponse = (energyType, process) => {
        const { dataState } = this.props;
        const { calendarAppointments } = dataState;

        return calendarAppointments.filter(
            object => object.energy === energyType && object.process === process
        );
    };

    filterDateAppointment = ({ daysList: values }, type, condition) => {
        const arrSend = [];
        if (values !== undefined)
            values
                .filter(element =>
                    type === 'express'
                        ? element[type] === condition && element.available
                        : element[type] === condition
                )
                .forEach(val => arrSend.push(new Date(val.date)));
        if (type === 'available' && !condition) {
            const lastDay = values && new Date(values.slice(-1)[0].date);
            const today = new Date();
            const tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);

            arrSend.push({
                before: tomorrow,
                after: lastDay,
            });
        }
        return arrSend;
    };

    handleDateCalendarOption = (energyType, type) => {
        const { fields } = this.state;
        const { dateContract } = fields.common;
        const isSwitch = dateContract.switch;

        // render disabled day is situation === SWITCH
        if (isSwitch) {
            if (type === 'disabled') {
                const switchDropdownValues = (() => {
                    const day =
                        Config.DefaultSwitchDueDate[dateContract.switch.value][
                            energyType
                        ];

                    return helpers.addDays(new Date(), day);
                })();

                return [
                    {
                        before: switchDropdownValues,
                        after: helpers.addMonth(new Date(), 1),
                    },

                    {
                        daysOfWeek: [0, 6],
                    },
                ];
            }

            return [];
        }

        if (type === 'selected')
            return this.filterDateAppointment(
                ...this.filterCalendarAppointmentResponse(
                    energyType,
                    'MOVE_IN'
                ),
                'express',
                true
            );
        return this.filterDateAppointment(
            ...this.filterCalendarAppointmentResponse(energyType, 'MOVE_IN'),
            'available',
            false
        );
    };

    handleDropdownContract = object => {
        const { fields, setting } = this.state;
        const { dateContract } = fields.common;
        const {
            userState: { energyTypes },
        } = this.props;

        if (object.value === Wording.contractDate.dropdownValues[0].value) {
            dateContract.switch = object;
            setting.showBottomText = true;
        } else if (
            object.value === Wording.contractDate.dropdownValues[1].value
        ) {
            dateContract.switch = object;
            setting.showBottomText = false;
        }
        energyTypes.forEach(energy => {
            let day = 0;
            if (object.value === Wording.contractDate.dropdownValues[0].value) {
                day = Config.DefaultSwitchDueDate.NOW[energy];
            } else if (
                object.value === Wording.contractDate.dropdownValues[1].value
            ) {
                day = Config.DefaultSwitchDueDate.LATER[energy];
            }

            const actualDate = new Date();
            const newDate = helpers.addDays(actualDate, day);
            dateContract[energy].date = newDate.getDate();
            dateContract[energy].month = newDate.getMonth();
            dateContract[energy].year = newDate.getFullYear();
        });

        return this.setState({
            fields,
            setting,
        });
    };

    checkBirthdate = birthdate => {
        const reg = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
        return reg.test(birthdate);
    };

    validateBaseInput = () => {
        const { fields, setting } = this.state;
        const { userState } = this.props;
        if (userState.userType === UserScreenWording.userType.professional) {
            return (
                this.handleConditionCommon(fields.common) &&
                this.handleConditionDataPro(fields.pro, setting.validSiret)
            );
        }
        return this.handleConditionCommon(fields.common);
    };

    validateCode = () => {
        const { validateCodeScreenDispatch, userState } = this.props;
        const { EL, NG, setting } = this.state;

        validateCodeScreenDispatch({
            noModeChange: true,
            PDL: EL,
            PCE: NG,
            energyTypes: userState.energyTypes,
            userType: userState.userType,
        });
        setting.showErrorStep1 = true;
        setting.resetEstimate = true;
        this.setState({ setting });
    };

    errorDate = birthdate => {
        if (birthdate === '' || birthdate === undefined)
            return Wording.error.missingBirthDate;

        if (
            birthdate !== undefined &&
            birthdate !== '' &&
            helpers.birthCondition(birthdate)
        )
            return Wording.error.dateError;

        return null;
    };

    checkFirstModuleCondition() {
        const { EL, NG } = this.state;
        const {
            userState: { energyTypes },
        } = this.props;
        const isBoth = energyTypes.length === 2;
        if (isBoth)
            return (
                helpers.handleConditionPDLPCE(EL) &&
                helpers.handleConditionPDLPCE(NG)
            );
        return (
            (energyTypes[0] === 'EL' && helpers.handleConditionPDLPCE(EL)) ||
            (energyTypes[0] === 'NG' && helpers.handleConditionPDLPCE(NG))
        );
    }

    renderNotLoginBaseInput() {
        const { userState } = this.props;
        const { setting, nonExistingForm, fields } = this.state;
        const isFranceBirthCountry =
            fields.common.birthTown.country === 'FRANCE';
        const validation = {
            birthdate:
                setting.checkError.birthdate &&
                (!nonExistingForm ||
                    !this.checkBirthdate(nonExistingForm.birthdate)) &&
                Wording.error.invalideBirthDate,
            birthCountry:
                setting.checkError.birthCountry &&
                !fields.common.birthTown.country &&
                AddressContainerWording.error.mainText,
            birthPostalCode:
                setting.checkError.birthPostalCode &&
                isFranceBirthCountry &&
                !fields.common.birthTown.postalCode &&
                AddressContainerWording.error.mainText,
            birthCity:
                setting.checkError.birthCity &&
                !fields.common.birthTown.townName &&
                AddressContainerWording.error.mainText,
        };
        return (
            <>
                <div className="spacing-form-input">
                    <CustomDate
                        value={
                            fields.common.birthdate === undefined
                                ? ''
                                : fields.common.birthdate
                        }
                        text={Wording.birthdate}
                        styleV2
                        onDateChange={date => this.handleDateChange(date)}
                        error={
                            setting.showError &&
                            this.errorDate(fields.common.birthdate)
                        }
                    />
                    <div className="country-postalcode__drop-down-container">
                        <CustomSelectV2
                            customClassName="reformat_select"
                            placeholder={Wording.countryBirth}
                            options={countriesOptions}
                            halfSize={isFranceBirthCountry}
                            handleChange={obj => {
                                this.handleChangeSelect(
                                    obj,
                                    'common',
                                    'birthTown',
                                    'country'
                                );
                            }}
                            value={fields.common.birthTown.country}
                            onBlur={() => this.onBlur('birthCountry')}
                            error={validation.birthCountry}
                        />

                        {isFranceBirthCountry && (
                            <CustomSelectV2
                                onBlur={() => this.onBlur('birthPostalCode')}
                                placeholder="Code Postal"
                                isLoading={setting.isLoadingPC}
                                halfSize
                                onInputChange={value =>
                                    this.handleInputChangeAddressContainer(
                                        value,
                                        'codeBirthTown'
                                    )
                                }
                                options={setting.postalCodes}
                                handleChange={obj =>
                                    this.handleChangeDropdownSelect(
                                        obj,
                                        'birthTown'
                                    )
                                }
                                value={{
                                    id: fields.common.birthTown.postalCode,
                                    label: fields.common.birthTown.postalCode,
                                    value: fields.common.birthTown.postalCode,
                                }}
                                onFocus={e =>
                                    this.onFocusDropdown(e, 'birthTown')
                                }
                                error={validation.birthPostalCode}
                            />
                        )}
                    </div>
                    <LabeledInput
                        label={Wording.cityBirth}
                        name={Wording.cityBirth}
                        value={fields.common.birthTown.townName}
                        isLocked={isFranceBirthCountry}
                        messages={{ error: validation.birthCity }}
                        type="text"
                        onBlur={() => this.onBlur('birthCity')}
                        onChange={e =>
                            this.handleChangeSelect(
                                { value: e },
                                'common',
                                'birthTown',
                                'townName'
                            )
                        }
                    />
                </div>
                {userState.userType ===
                    UserScreenWording.userType.professional &&
                    this.renderNotLoginProInput()}
            </>
        );
    }

    renderNotLoginProInput() {
        const { dataState } = this.props;
        const { setting, fields } = this.state;
        const validation = {
            SIRET_length:
                setting.checkError.SIRET &&
                (!fields.pro.SIRET || fields.pro.SIRET.length !== 14) &&
                Wording.error.SIRET,
            SIRET_valid:
                setting.checkError.SIRET &&
                (!fields.pro.SIRET ||
                    fields.pro.SIRET.length !== 14 ||
                    setting.validSiret !== 200) &&
                Wording.error.incorrectSIRET,
            APECode:
                setting.checkError.APECode &&
                !fields.pro.APECode &&
                Wording.error.APE,
            socialReason:
                setting.checkError.socialReason &&
                !fields.pro.socialReason &&
                Wording.error.missingSocialReason,
            legalForm:
                setting.checkError.legalForm &&
                !dataState.pro.legalForm &&
                Wording.error.missingLegalForm,
        };
        return (
            <>
                <LabeledInput
                    label={Wording.SIRET}
                    name="SIRET"
                    type="text"
                    value={dataState.pro.SIRET}
                    onChange={e => this.handleChangeInput(e, null, 'SIRET')}
                    messages={{
                        error:
                            validation.SIRET_length || validation.SIRET_valid,
                    }}
                    onBlur={() => this.onBlur('SIRET')}
                />
                <LabeledInput
                    label={Wording.NAFCode}
                    name="APECode"
                    type="text"
                    value={dataState.pro.APECode}
                    onChange={e => this.handleChangeInput(e, null, 'APECode')}
                    messages={{ error: validation.APECode }}
                    onBlur={() => this.onBlur('APECode')}
                    isLocked={setting.validSiret === 200}
                />
                <LabeledInput
                    label={Wording.socialReason}
                    name="APECode"
                    type="text"
                    value={dataState.pro.socialReason}
                    onChange={e =>
                        this.handleChangeInput(e, null, 'socialReason')
                    }
                    messages={{ error: validation.socialReason }}
                    onBlur={() => this.onBlur('socialReason')}
                />
                <CustomSelectV2
                    placeholder={Wording.legalForm.title}
                    handleChange={e =>
                        this.handleChangeSelect(e, 'pro', 'legalForm')
                    }
                    options={Wording.legalForm.values}
                    label={Wording.legalForm.title}
                    name="legalForm"
                    type="text"
                    value={dataState.pro.legalForm}
                    onBlur={() => this.onBlur('legalForm')}
                    error={validation.legalForm}
                />
            </>
        );
    }

    renderLoginBaseInput() {
        const { userState, dataState } = this.props;
        return (
            <div className="adress-locked-group">
                <div className="spacing-form-input">
                    <LabeledInput
                        label={Wording.civility.title}
                        name="civility"
                        type="text"
                        value={dataState.common.civility}
                        halfSize
                        isLockedV2
                    />
                    <LabeledInput
                        label={Wording.name}
                        name="name"
                        type="text"
                        value={dataState.common.name}
                        isLockedV2
                    />
                    <LabeledInput
                        label={Wording.surname}
                        name="surname"
                        type="text"
                        value={dataState.common.surname}
                        isLockedV2
                    />
                    <LabeledInput
                        label={Wording.email}
                        name="email"
                        type="text"
                        value={dataState.common.email}
                        isLockedV2
                    />
                    <LabeledInput
                        label={Wording.tel}
                        name="tel"
                        type="text"
                        value={dataState.common.tel}
                        isLockedV2
                    />
                    {/* <LabeledInput // No birth related data stored in backend
                        label={Wording.birthdate}
                        name="birthdate"
                        type="text"
                        isLockedV2
                    />
                    <div className="country-postalcode__drop-down-container">
                        <LabeledInput
                            label={Wording.countryBirth}
                            name="countryBirth"
                            type="text"
                            isLockedV2
                            halfSize
                        />
                        <LabeledInput
                            label={Wording.postalCode}
                            name="postalCode"
                            type="text"
                            isLockedV2
                            halfSize
                        />
                    </div>
                    <LabeledInput
                        label={Wording.cityBirth}
                        name="cityBirth"
                        type="text"
                        isLockedV2
                    /> */}
                    {userState.userType ===
                        UserScreenWording.userType.professional &&
                        this.renderLoginProInput()}
                </div>
                <div className="side-info-container side-info-container-bar">
                    <Typography className="text-info">
                        {Wording.why_locked_client_1}
                    </Typography>
                    <Typography className="text-info">
                        {Wording.why_locked_client_2}
                    </Typography>
                </div>
            </div>
        );
    }

    renderLoginProInput() {
        const { dataState } = this.props;
        return (
            <>
                <LabeledInput
                    label={Wording.SIRET}
                    name="SIRET"
                    type="text"
                    isLockedV2
                    value={dataState.pro.SIRET}
                />
                <LabeledInput
                    label={Wording.NAFCode}
                    name="APECode"
                    type="text"
                    isLockedV2
                    value={dataState.pro.APECode}
                />
                <LabeledInput
                    label={Wording.socialReason}
                    name="socialReason"
                    type="text"
                    isLockedV2
                    value={dataState.pro.socialReason}
                />
                <LabeledInput
                    label={Wording.legalForm.title}
                    name="legalForm"
                    type="text"
                    isLockedV2
                    value={dataState.pro.legalForm}
                />
            </>
        );
    }

    renderNewBillingAddressInput() {
        const { setting, fields } = this.state;
        return (
            <>
                <LabeledInput
                    label={AddressContainerWording.billingAddress}
                    name={AddressContainerWording.billingAddress}
                    type="text"
                    value={fields.common.newBillingAddress.address}
                    onChange={e =>
                        this.handleChangeInput(
                            e,
                            'newBillingAddress',
                            'address'
                        )
                    }
                />
                <div className="link-divider" />
                <CustomSelectV2
                    placeholder="Code Postal"
                    isLoading={setting.isLoadingPC}
                    onInputChange={value => {
                        this.handleInputChangeAddressContainer(
                            value,
                            'codeNewBillingAddressText'
                        );
                    }}
                    options={setting.postalCodes}
                    handleChange={obj => {
                        this.handleChangeDropdownSelect(
                            obj,
                            'newBillingAddress'
                        );
                    }}
                    value={{
                        id: fields.common.newBillingAddress.postalCode,
                        label: fields.common.newBillingAddress.postalCode,
                        value: fields.common.newBillingAddress.postalCode,
                    }}
                    onFocus={e => {
                        this.onFocusDropdown(e, 'newBillingAddress');
                    }}
                />
                <div className="link-divider" />
                <LabeledInput
                    label={AddressContainerWording.city}
                    name={AddressContainerWording.city}
                    value={fields.common.newBillingAddress.city}
                    isLocked
                    type="text"
                    onChange={e =>
                        this.onFieldChange('newBillingCity', e, 'new')
                    }
                />
            </>
        );
    }

    renderAddCoOwnerInput() {
        const { dataState } = this.props;

        return (
            <>
                <>
                    <CustomSelectV2
                        customClassName="reformat_select"
                        placeholder={Wording.civility.title}
                        options={Wording.civility.values}
                        halfSize
                        handleChange={e =>
                            this.handleChangeSelect(
                                e,
                                'part',
                                'coOwner',
                                'civility'
                            )
                        }
                        value={dataState.part.coOwner.civility}
                    />
                    <div className="link-divider" />
                    <LabeledInput
                        label={Wording.coOwner.firstName}
                        name="postalCode"
                        type="text"
                        value={dataState.part.coOwner.firstName}
                        onChange={e =>
                            this.handleChangeInput(e, 'coOwner', 'firstName')
                        }
                    />
                    <div className="link-divider" />
                    <LabeledInput
                        label={Wording.coOwner.lastName}
                        name="city"
                        type="text"
                        value={dataState.part.coOwner.lastName}
                        onChange={e =>
                            this.handleChangeInput(e, 'coOwner', 'lastName')
                        }
                    />
                </>
            </>
        );
    }

    handleChangeCode = (field, value) => {
        const { resetScreenTo, isCurrentScreen } = this.props;
        const optionalSetter = {};
        let cleanValue = value;
        if (field === 'EL' || field === 'NG')
            if (cleanValue !== undefined) {
                const regex = /\D|\d{15,}/g;
                const tmp = regex.test(cleanValue);
                if (tmp) cleanValue = cleanValue.slice(0, -1);
            }
        optionalSetter.errorFields = {
            [`${field}Error`]: !helpers.handleConditionPDLPCE(cleanValue),
        };
        this.setState({
            [field]: cleanValue,
            ...optionalSetter,
        });

        if (!isCurrentScreen) {
            resetScreenTo();
        }
    };

    onFieldChange(field, value, form = undefined) {
        const { existingForm, nonExistingForm } = this.state;
        const { isCurrentScreen, resetScreenTo } = this.props;
        if (!isCurrentScreen) resetScreenTo();

        if (form === undefined)
            this.setState({
                [field]: value,
            });
        else if (form === 'exist')
            this.setState({
                existingForm: {
                    ...existingForm,
                    [field]: value,
                },
            });
        else if (form === 'new')
            this.setState({
                nonExistingForm: {
                    ...nonExistingForm,
                    [field]: value,
                },
            });
    }

    onBlur(field) {
        const { setting } = this.state;
        if (setting.checkError[field] !== undefined) {
            setting.checkError[field] = true;
            this.setState({ setting });
        }
    }

    render() {
        const {
            codeState,
            dataState,
            summaryState: { contracts },
            userState: { energyTypes },
            mainState,
            nextScreen,
            canGoNext,
            isCurrentScreen,
            isModalOpen,
        } = this.props;
        const state = this.state;
        const { errorFields } = this.state;
        const { setting } = this.state;
        const isLogin =
            dataState.logingInformation &&
            dataState.logingInformation.accessToken !== '';
        let bakcendError = null;
        const isCodeNotSet = codeState.PDL === '' && codeState.PCE === '';
        if (mainState && mainState.errorFrom === 'CODE_SCREEN_VALIDATE_FAILURE')
            bakcendError = mainState.error;

        contracts.forEach(contract => {
            if (
                contract.deliveryPoint.address &&
                contract.deliveryPoint.address.postalCode !==
                    dataState.common.firstAddress.postalCode &&
                !dataState.logingInformation.accessToken
            ) {
                errorFields[contract.energy] =
                    CodeScreenWording.inputError.errorNotSamePostalCode;
            } else {
                errorFields[contract.energy] = undefined;
            }
        });

        energyTypes.forEach(it => {
            if (bakcendError) {
                if (codeState.PDL === '' && codeState.PCE === '') {
                    errorFields[it] = bakcendError;
                } else {
                    errorFields[it] = undefined;
                }
            }
        });

        return (
            <>
                <div ref={this.myRef} />
                <SectionLayout
                    step={DataScreen.navigationOptions.step}
                    isModal={isModalOpen}
                >
                    <Title
                        titleFormatGowun="Où Wekiwi doit m'"
                        titleFormatLexand="alimenter ?"
                        noGap
                    />
                    <div className="spacing-form-input">
                        <Typography className="subtitle-p2">
                            Pour éviter les erreurs, vérifie attentivement que
                            les informations sont correctes !
                        </Typography>
                        {isLogin
                            ? this.renderLoginBaseInput()
                            : this.renderNotLoginBaseInput()}
                        {isCodeNotSet ? (
                            <>
                                <Typography className="subtitle-p2">
                                    {Wording.rappel_pdl}
                                </Typography>
                                {energyTypes.map(it => (
                                    <LabeledInput
                                        key={it}
                                        value={state[it]}
                                        messages={{ error: errorFields[it] }}
                                        label={
                                            CodeScreenWording.placeholder[it]
                                        }
                                        onChange={v =>
                                            this.handleChangeCode(it, v)
                                        }
                                        type="text"
                                    />
                                ))}
                                <div className="__button-container">
                                    <CustomButton
                                        disabledBackgroundColor="#f3f3f3"
                                        disabled={
                                            !this.checkFirstModuleCondition() ||
                                            !this.validateBaseInput()
                                        }
                                        title="Valider"
                                        color="orange"
                                        loading={mainState.loading}
                                        onClick={() => {
                                            this.validateCode();
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="adress-locked-group">
                                <div className="spacing-form-input">
                                    <LabeledInput
                                        label={AddressContainerWording.address}
                                        name="address"
                                        type="text"
                                        isLockedV2
                                        defaultValue={
                                            dataState.common.address.address
                                        }
                                        onChange={e =>
                                            this.onFieldChange(
                                                'address',
                                                e,
                                                'new'
                                            )
                                        }
                                    />
                                    <LabeledInput
                                        label={
                                            AddressContainerWording.postalCode
                                        }
                                        name="postalCode"
                                        type="text"
                                        isLockedV2
                                        defaultValue={
                                            dataState.common.address.postalCode
                                        }
                                        onChange={e =>
                                            this.onFieldChange(
                                                'postalCode',
                                                e,
                                                'new'
                                            )
                                        }
                                    />
                                    <LabeledInput
                                        label={AddressContainerWording.city}
                                        name="city"
                                        type="text"
                                        isLockedV2
                                        defaultValue={
                                            dataState.common.address.city
                                        }
                                        onChange={e =>
                                            this.onFieldChange('city', e, 'new')
                                        }
                                    />
                                </div>
                                <div
                                    className={`side-info-container ${isLogin &&
                                        'side-info-container-bar'}`}
                                >
                                    <Typography className="text-info">
                                        {Wording.why_locked_address_1}
                                    </Typography>
                                    <Typography className="text-info">
                                        {Wording.why_locked_address_2}
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </div>
                    <CustomToggleV2
                        id={Wording.toggleInputs.newBillingAddress.id}
                        text={Wording.toggleInputs.newBillingAddress.title}
                        checked={setting.checkedNewBillingAddress}
                        isLinked
                        handleCheck={e =>
                            this.handleToggle(e, 'checkedNewBillingAddress')
                        }
                    />
                    {setting.checkedNewBillingAddress &&
                        this.renderNewBillingAddressInput()}
                    <CustomToggleV2
                        id="civilityCoOwner"
                        text="Ajouter une personne co-titulaire"
                        checked={setting.checkedAddCoOwner}
                        isLinked
                        handleCheck={e =>
                            this.handleToggle(e, 'checkedAddCoOwner')
                        }
                    />
                    {setting.checkedAddCoOwner && this.renderAddCoOwnerInput()}
                    {isCurrentScreen && !isCodeNotSet && (
                        <div className="validate-button-top-space">
                            <CustomButton
                                disabledBackgroundColor="#f3f3f3"
                                title="Valider"
                                color="orange"
                                disabled={!canGoNext}
                                onClick={nextScreen}
                                loading={mainState.loading}
                            />
                        </div>
                    )}
                </SectionLayout>
            </>
        );
    }
}

const mapStateToProps = state => ({
    dataState: state.dataReducer,
    userState: state.userReducer,
    codeState: state.codeReducer,
    summaryState: state.summaryReducer,
    surveyState: state.surveyReducer,
    paymentState: state.paymentReducer,
    packageState: state.packageReducer,
    autorizedBillingModes: state.packageReducer.autorizedBillingModes,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    validateCodeScreenDispatch: payload =>
        dispatch(validateCodeScreen(payload)),
    validateDataScreenDispatch: payload =>
        dispatch(actions.validateDataScreenSuccess(payload)),
    validateDataScreenFinalDispatch: payload =>
        dispatch(actions.validateDataScreenSuccessFinal(payload)),
    fetchCalendarAppointmentDispatch: payload =>
        dispatch(actions.fetchCalendarAppointment(payload)),
    dataScreenSaveCommonDispatch: payload =>
        dispatch(actions.dataScreenSaveCommon(payload)),
    validateSurveyScreenDispatch: payload =>
        dispatch(validateSurveyScreen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataScreen);
